import logo from '../assets/crispo-logo.png'

const Layout: React.FC = props => (
  <div>
    <div className='image'>
      <img src={logo} alt='Crispo logo' className='absolute h-24 pt-5 mx-8' />
    </div>
    <div className='flex items-center h-screen lg:bg-gray-50'>
      {props.children}
    </div>
  </div>
)

export default Layout
