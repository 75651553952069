import { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import api from '../util/api'
import Layout from './Layout'

const ResetPasswordForm = () => {
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errMessage, setErrMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const history = useHistory()
  const { token } = useParams<{ token: string }>()

  const match = password === confirmPassword

  const setFirst = (event: any) => {
    setPassword(event.target.value)
  }

  const setSecond = (event: any) => {
    setConfirmPassword(event.target.value)
  }

  const handleFormSubmit = (e: any) => {
    e.preventDefault()
    setLoading(true)
    api
      .updatePassword(token, password)
      .then(res => {
        history.push('/success')
      })
      .catch(err => {
        console.log('error', JSON.stringify(err, null, 2))
        return setErrMessage(err.message)
      })
      .finally(() => {
        setLoading(false)
        setPassword('')
        setConfirmPassword('')
      })
  }

  return (
    <Layout>
      <div className='w-full max-w-lg px-6 py-6 m-auto lg:border lg:rounded-lg lg:bg-white lg:shadow-md lg:border-primaryBorder'>
        <p className='mb-6 text-2xl text-crispo'>
          Crispo Rewards Password Reset
        </p>

        <form onSubmit={handleFormSubmit}>
          <div>
            <label htmlFor='new' className='text-gray-600'>
              Password
            </label>
            <input
              value={password}
              autoComplete='off'
              onChange={setFirst}
              type='password'
              className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
              id='new'
              placeholder='********'
            />
          </div>
          <div>
            <label htmlFor='confirm' className='text-gray-600'>
              Confirm password
            </label>
            <input
              value={confirmPassword}
              onChange={setSecond}
              type='password'
              className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
              id='confirm'
              placeholder='********'
            />
          </div>
          <p className='text-xs italic text-red-500'>{errMessage}</p>
          {loading ? (
            <div className='flex items-center justify-center mt-3 mb-1'>
              <BeatLoader size='16px' color='#0166ae' />
            </div>
          ) : (
            <div className='flex items-center justify-between mt-2'>
              <div>
                {!match && (
                  <small className='text-red-500'>Passwords must match</small>
                )}
              </div>
              <button
                type='submit'
                className={`bg-crispo hover:bg-blue-800 text-white text-sm font-bold py-2 px-4 rounded ${
                  !match ? 'opacity-50' : ''
                }`}
                disabled={!match}
              >
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
    </Layout>
  )
}

export default ResetPasswordForm
