import Layout from './Layout'

const Success = () => {
  return (
    <Layout>
      <div className='m-auto'>
        <p className='mb-3 text-2xl leading-relaxed text-center text-green-500'>
          Password successfully changed
        </p>
        <p className='leading-relaxed text-center text-gray-700'>
          You may login using your new password.
        </p>
      </div>
    </Layout>
  )
}

export default Success
