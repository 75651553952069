import logo from '../assets/crispo-logo.png'

const NotFound = () => {
  return (
    <div className='flex items-center justify-center h-screen bg-gray-50'>
      <div className='image'>
        <img src={logo} alt='Crispo logo' className='h-20 mx-8 my-5' />
      </div>
      <p className='text-4xl text-center text-crispo'>
        Crispo Rewards Forgot Password Portal
      </p>
    </div>
  )
}

export default NotFound
