import axios from 'axios'
import { FORGOT_PASSWORD_URL } from '../config'

const api = {
  updatePassword: function (token: string, password: string) {
    return axios({
      method: 'post',
      headers: { token },
      url: FORGOT_PASSWORD_URL,
      data: { password },
    })
  },
}

export default api
