import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotFound from './components/NotFound';
import ResetPasswordForm from './components/ResetPasswordForm';
import Success from "./components/Success";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/success" component={Success} />
        <Route exact path="/:token" component={ResetPasswordForm} />
        <Route path="*" exact={true} component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
